import { configureStore } from '@reduxjs/toolkit';
import { cartSlice } from './reducers/cartSlice';
import { initAuthPersistency } from './initAuthPersistency';
import { navigationSlice } from './reducers/navigationSlice';
import { eventsSlice } from '@/reducers/eventsSlice';
import { authSlice } from '@/reducers/authSlice';
import { forgotPasswordSlice } from '@/reducers/forgotPasswordSlice';
import { profileSlice } from '@/reducers/profileSlice';
import { changeEmailSlice } from '@/reducers/changeEmailSlice';
import { verifyPasswordSlice } from '@/reducers/verifyPasswordSlice';
import { changePasswordSlice } from '@/reducers/changePasswordSlice';
import { geoLocationSlice } from '@/reducers/geoLocationSlice';
import { platformMessageSlice } from '@/reducers/platformMessageSlice';

// Set up asyncThunks incase it's a function
const asyncFunctionMiddleware =
    (storeAPI: any) => (next: any) => (action: any) => {
        // If the "action" is actually a function instead...
        if (typeof action === 'function') {
            // then call the function and pass `dispatch` and `getState` as arguments
            return action(storeAPI.dispatch, storeAPI.getState);
        }

        // Otherwise, it's a normal action - send it onwards
        return next(action);
    };

const createStore = () => {
    // Configure the store and make it a const so we can access it here
    const store = configureStore({
        reducer: {
            auth: authSlice.reducer,
            forgotPassword: forgotPasswordSlice.reducer,
            tickets: eventsSlice.reducer,
            profile: profileSlice.reducer,
            changeEmail: changeEmailSlice.reducer,
            verifyPassword: verifyPasswordSlice.reducer,
            changePassword: changePasswordSlice.reducer,
            cartSlice: cartSlice.reducer,
            geoLocation: geoLocationSlice.reducer,
            platformMessages: platformMessageSlice.reducer,
            navigation: navigationSlice.reducer,
        },
        // middleware: [asyncFunctionMiddleware],
    });

    // Call loading and return store
    initAuthPersistency(store);
    return store;
};

// Ignore some typings here that can not be typed, possibly can but very difficult
// @ts-ignore
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;
export type Store = ReturnType<typeof createStore>;
// @ts-ignore
export type AppDispatch = typeof createStore.dispatch;
export default createStore;
