"use client";

import React, { FC, ReactNode, useEffect } from "react";
import { useAppSelector } from "../state/hooks";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { logSegmentIdentify } from "@/utils/segment-analytics";
declare global {
  interface Window {
    analytics: AnalyticsBrowser;
    // Added it for OAR-4853
    OnetrustActiveGroups?: string;
    destinationPreferences?: any;
    // analytics: SegmentAnalytics;
    fcWidget?: {
      user: {
        setEmail: (email?: string) => void;
      };
      [key: string]: unknown;
    };
  }
}

/**
 *
 * @param email - User's email
 * @param attempt - Current attempt to register user
 *
 * This method is used to register the user's email to Freshchat.
 * Because Freshchat is initialized asynchronously, the window.fcWidget.user.setEmail method
 * will not be present for a user whose credentials have been fetched using autologin.
 * But it will if the use logs in using his credentials.
 * To tackle the first case we are attempting again, max 5 times to check if the method is
 * present in the window object.
 */
const registerUserToFreshChat = (email: string, attempt?: number) => {
  const maxAttempts = 5;
  const delayModifier = 1000;
  if (window?.fcWidget?.user?.setEmail) {
    window.fcWidget.user.setEmail(email);
  } else {
    const newAttemptNumber = (attempt ?? 0) + 1;
    if (newAttemptNumber >= maxAttempts) return;
    setTimeout(() => {
      registerUserToFreshChat(email, newAttemptNumber);
    }, delayModifier);
  }
};

/**
 * Wrapper component holding logic to identify users and pass their info
 * to our 3rd party tools.
 */
export const UserIdentify: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const customerInfo = useAppSelector(state => state.auth.token?.customerInfo);

  /**
   * Listen for any customer info update and send the logs to Segment
   * and set user's email at fcWidget.
   * Supports user's login, auto login, info and password change
   */
  useEffect(() => {
    if (customerInfo && window?.analytics?.identify) {
      logSegmentIdentify(window.analytics.identify, customerInfo);
    }
    if (customerInfo?.email) {
      registerUserToFreshChat(customerInfo.email);
    }
    if (!customerInfo?.email && window?.fcWidget?.user?.setEmail) {
      // Unregister the user if he logs out
      window.fcWidget.user.setEmail(undefined);
    }
  }, [customerInfo]);
  return <>{children}</>;
};