import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_COUNTRY_CODE } from '../utils/i18n';
import { fetchIPStack } from '@/utils/fetchIPStack';

export interface GeoLocationSliceState {
    status: 'NOT_FETCHED' | 'FETCHED' | 'IN_PROGRESS' | 'FAILED';
    countryCode: string;
    isCountryCodeMocked: boolean;
    errorMsg: string;
}

export const geoLocationSliceInitialState = {
    status: 'NOT_FETCHED',
    countryCode: DEFAULT_COUNTRY_CODE,
    isCountryCodeMocked: false,
    errorMsg: '',
} as GeoLocationSliceState;

export const fetchUserGeoLocationAction = createAsyncThunk(
    'geo/fetchUserGeoLocation',
    async (payload, { rejectWithValue }) => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const geoLocationResponse = await fetchIPStack();

            //@ts-ignore
            if (geoLocationResponse?.error) {
                //@ts-ignore
                const errorMessage = `${geoLocationResponse.error.code}: ${geoLocationResponse.error.info}`;
                return rejectWithValue({ message: errorMessage });
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return geoLocationResponse;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);

interface ErrorMessageObject {
    message: string;
}

export const geoLocationSlice = createSlice({
    name: 'geoLocation',
    initialState: geoLocationSliceInitialState,
    reducers: {
        changeUserCountryCode: (state, action) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            state.countryCode = action.payload;
            state.isCountryCodeMocked = true; // set up to true if it has changed from developer tools even once
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserGeoLocationAction.pending, (state) => {
            state.status = 'IN_PROGRESS';
            state.countryCode = geoLocationSliceInitialState.countryCode;
            state.errorMsg = '';
        });
        builder.addCase(
            fetchUserGeoLocationAction.fulfilled,
            (state, { payload }) => {
                state.status = 'FETCHED';
                //@ts-ignore
                const { country_code: countryCode } = payload;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                state.countryCode = countryCode;
                state.errorMsg = '';
            },
        );
        builder.addCase(
            fetchUserGeoLocationAction.rejected,
            (state, { payload }) => {
                state.status = 'FAILED';
                state.countryCode = geoLocationSliceInitialState.countryCode;
                state.errorMsg = (payload as ErrorMessageObject).message;
            },
        );
    },
});

export const changeUserCountryCodeActions = geoLocationSlice.actions;
