// @ts-nocheck
'use client';

import FetchGeoLocationOnAppLoad from '@/providers/FetchGeoLocationOnAppLoad';
import FetchTicketsOnAppLoadProvider from '@/providers/FetchTicketsOnAppLoadProvider';
import { InitializeCartOnAppLoad } from '@/providers/InitializeCartOnAppLoad';
import SyncTokenProvider from '@/providers/SyncTokenProvider';
import { UserIdentify } from '@/providers/UserIdentify';
// import { TrpcProvider } from "@/utils/trpc-provider";
import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import createStore from './createStore';
import { CookiesConsentManager } from '@/components/cookie-consent-manager';
import { useGetEnvironmentVariable } from '@/hooks/useGetEnvironmentVariable';
const store = createStore();
export { store };
export const Providers = ({
  children
}: {
  children: JSX.Element | JSX.Element[] | Promise<JSX.Element> | ReactNode;
}) => {
  const {
    ENVIRONMENT,
    ENABLE_SEGMENT
  } = useGetEnvironmentVariable(['ENVIRONMENT', 'ENABLE_SEGMENT']);
  return <>
            {ENABLE_SEGMENT === 'true' && <CookiesConsentManager />}
            <Provider store={store} data-sentry-element="Provider" data-sentry-source-file="wrapWithProviders.tsx">
                <UserIdentify data-sentry-element="UserIdentify" data-sentry-source-file="wrapWithProviders.tsx">
                    {/* <TrpcProvider> */}
                    <SyncTokenProvider data-sentry-element="SyncTokenProvider" data-sentry-source-file="wrapWithProviders.tsx">
                        <FetchTicketsOnAppLoadProvider data-sentry-element="FetchTicketsOnAppLoadProvider" data-sentry-source-file="wrapWithProviders.tsx">
                            <FetchGeoLocationOnAppLoad data-sentry-element="FetchGeoLocationOnAppLoad" data-sentry-source-file="wrapWithProviders.tsx">
                                <InitializeCartOnAppLoad data-sentry-element="InitializeCartOnAppLoad" data-sentry-source-file="wrapWithProviders.tsx">
                                    {children}
                                </InitializeCartOnAppLoad>
                            </FetchGeoLocationOnAppLoad>
                        </FetchTicketsOnAppLoadProvider>
                    </SyncTokenProvider>
                    {/* </TrpcProvider> */}
                </UserIdentify>
            </Provider>
        </>;
};