'use client';

import React from 'react';
import { ActionsBlockProps } from '@segment/consent-manager/types/types';
import messages from './messages';
import Button from '../Button';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { ButtonType } from '@/enums/ButtonType';

/**
 *
 * @description The bottom buttons of the cookies banner
 *  the FC had to be omitted in order to match ConsentManager's type
 *
 * @param props Default segment actions
 * @returns The 3 buttons at Segment cookies banner
 */
export const CookiesBannerButtons = (props: ActionsBlockProps & {
  SEGMENT_ANALYTICS_TRACK_WRITE_KEY?: string;
  SEGMENT_CDN_PROXY_HOST?: string;
  SEGMENT_API_PROXY_HOST?: string;
}) => {
  const {
    acceptAll,
    denyAll,
    changePreferences,
    SEGMENT_ANALYTICS_TRACK_WRITE_KEY,
    SEGMENT_CDN_PROXY_HOST,
    SEGMENT_API_PROXY_HOST
  } = props;
  const {
    accept_all_cookies,
    reject_all_cookies,
    cookie_settings
  } = messages;
  const handleAcceptCookies = () => {
    const analytics = AnalyticsBrowser.load({
      writeKey: SEGMENT_ANALYTICS_TRACK_WRITE_KEY!,
      cdnURL: `https://${SEGMENT_CDN_PROXY_HOST}`
    }, {
      integrations: {
        'Segment.io': {
          apiHost: SEGMENT_API_PROXY_HOST!
        }
      }
    });
    if (typeof window !== 'undefined') {
      window.analytics = analytics;
    }
    const segmentEntries = Object.entries(sessionStorage).filter(([key, value]) => key.includes('segment') || key.includes('page'));
    segmentEntries.forEach(([key, value]) => {
      if (key.includes('segment')) {
        const action = key.split('-')[1];
        const params = JSON.parse(value);
        void window?.analytics?.track?.(action, {
          ...params,
          url: window.location.href
        });
      } else if (key.includes('page')) {
        void window?.analytics?.page?.(value);
      }
    });
    acceptAll();
  };
  return <div className="d-flex flex-column w-100 px-1 px-sm-2" data-sentry-component="CookiesBannerButtons" data-sentry-source-file="CookiesBannerButtons.tsx">
            <Button className="btn-onair cookies-banner-container__button" type={ButtonType.SOLID_SECONDARY} onClick={handleAcceptCookies} data-sentry-element="Button" data-sentry-source-file="CookiesBannerButtons.tsx">
                {accept_all_cookies}
            </Button>
            <Button className="btn-onair mt-1 cookies-banner-container__button" type={ButtonType.SOLID_SECONDARY} onClick={denyAll} data-sentry-element="Button" data-sentry-source-file="CookiesBannerButtons.tsx">
                {reject_all_cookies}
            </Button>
            <Button className="btn-onair mt-1 mb-2 cookies-banner-container__settings-button" type={ButtonType.OUTLINE_SECONDARY} onClick={changePreferences} data-sentry-element="Button" data-sentry-source-file="CookiesBannerButtons.tsx">
                {cookie_settings}
            </Button>
        </div>;
};